<template>
    <div class="about_us_en_div">
        <div>
            <div class="about_banner">
                <img :src="imgSrc[0]"/>
            </div>
            <div class="about_all_div allContentWidth">
                <div class="about_title_div">
                    <div class="about_title_img">
                        <!-- <img :src="imgSrc[1]"/> -->
                        <p>Eastrong Introduction</p>
                        <div class="line_div_box"></div>
                    </div>
                </div>
                <div class="about_content_div">
                    <p>
                        Established in 2002, Eastrong has strived to build extensive partnerships in the Asian region
                        and internationally. With our headquarter in Shanghai, our international strategy and corporate
                        performance has resulted in the company being ranked among the Top 5 Ocean freight forwarders in
                        the China-Oceania trade lanes. Today, we are a member of many leading industry associations, we
                        participate in conferences worldwide, and take a customer first approach to the partnerships we
                        form. Our reliable Ocean, Air, and Rail capacity allows us to create unique logistics solutions
                        for even the most complex challenges our customers face.
                    </p>
                </div>
                <!-- <div class="about_content_img_box">
                    <img :src="imgSrc[2]"/>
                </div> -->
                <div class="company_develop_div">
                    <swiper
                            class="swiper_about" ref="mySwiper"
                            :options="swiperAboutOption">
                        <swiper-slide v-for="(i, index) in imgAll" :key="index">
                            <div class="slide_item">
                                <p class="title_area">{{ i.name }}</p>
                                <p>{{ i.time }}</p>
                                <div class="gs_img_div">
                                    <img :src="imgSrc[3]"/>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <div class="swiper-button-prev swiper-button-prev-about-us" slot="button-prev"></div>
                    <div class="swiper-button-next swiper-button-next-about-us" slot="button-next"></div>
                </div>
                <img :src="imgSrc[4]" width="100%"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    computed: {},
    mounted() {
    },
    watch: {},
    methods: {},
    data() {
        var imgSrc = [];
        imgSrc.push(require('../../assets/img/aboutUs/about_banner.png'));//(1)
        imgSrc.push(require('../../assets/img/aboutUs/about_us.png'));//(1)
        imgSrc.push(require('../../assets/img/aboutUs/about_con.png'));//(2)
        imgSrc.push(require('../../assets/img/aboutUs/guangshu.png'));//(3)
        imgSrc.push(require('../../assets/img/aboutUs/gszz.jpg'));//(4)
        return {
            imgSrc: imgSrc,
            imgAll: [
                {id: 1, name: 'Shanghai', time: '2002-09'},//上海
                {id: 2, name: 'Ningbo', time: '2007-12'},//宁波
                {id: 3, name: 'Shenzhen', time: '2010-07'},//深圳
                {id: 4, name: 'Qingdao', time: '2010-11'},//青岛
                {id: 5, name: 'Tianjin', time: '2011-09'},//天津
                {id: 6, name: 'Xiamen', time: '2012-02'},//厦门
                {id: 7, name: 'Guangzhou', time: '2014-04'},//广州
                {id: 8, name: 'Dalian', time: '2016-08'},//大连
                {id: 13, name: 'Hong Kong', time: '2017-04'},//香港
                {id: 11, name: 'Vietnam - Hochiminh', time: '2018-03'},//越南-胡志明
                {id: 10, name: 'ILM', time: '2018-11'}, //融鑫仓库
                {id: 12, name: 'Malaysia - Port Kelang', time: '2019-04'}, //马来西亚-巴生
                {id: 15, name: 'Foshan', time: '2021-03'},//佛山
                {id: 14, name: 'Thailand - Bangkok', time: '2021-06'},//泰国
                {id: 9, name: 'SGIT', time: '2021-07'}, //捷纳罗
                {id: 16, name: 'Korea', time: '2021-09'},//韩国
                {id: 17, name: 'Malaysia - Penang', time: '2021-11'},//马来西亚-槟城
                {id: 20, name: 'Dubai', time: '2022-02'},//迪拜
                {id: 18, name: 'Vietnam - Hanoi', time: '2022-04'},//越南-河内
                {id: 19, name: 'Singapore', time: '2022-05'},//新加坡
                {id: 21, name: 'Indonesia - Jakarta', time: '2023-01'},//印尼-雅加达
                // {id: 22, name: 'Taiwan', time: '2023-05'},//台湾
                {id: 23, name: 'Thailand - Songkhla', time: '2023-06'},//泰国
            ],
            // Swiper 配置项
            swiperAboutOption: {
                direction: 'horizontal', // Swiper的滑动方向,可设置为水平方向切换 horizontal（默认） 或垂直方向切换 vertical 
                slidesPerView: 7, // Swiper的列数
                // spaceBetween: 30, //板块间距
                // 设置分页器
                // pagination: {
                //   el: ".swiper-pagination",
                //   type: 'bullets',//将分页的类型改为圆点（默认)
                //   clickable: true,// 点击可切换
                //   // hideOnClick :true, // 点击swiper-slide显隐
                // },
                loopedSlides: 19,
                //设置平滑
                // 设置前进后退按钮
                navigation: {
                    nextEl: ".swiper-button-next-about-us",
                    prevEl: ".swiper-button-prev-about-us",
                    // disabledClass: 'my-button-disabled-nav', //前进后退按钮不可用时的类名
                },
                speed: 2500,//匀速时间2.5秒 Swiper实现无缝匀速滚动
                // 设置自动轮播
                autoplay: {
                    delay: 0, // 每隔多少时间，自动播放
                    disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay。默认为true：停止。
                    stopOnLastSlide: true,
                },
                loop: false, //无缝轮播
                grabCursor: true, // 覆盖Swiper 时指针会变成手掌形状，拖动时指针会变成抓手形状
                observer: true,
                observeParents: true,
                preventClicksPropagation: false,
                // on:{
                //     touchMove: function(swiper,event){
                //         console.log(swiper.activeIndex)
                //     }
                // }
            },
        }
    }
}
</script>
<style lang="less">
.about_us_en_div {
  .about_banner {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .about_all_div {
    .about_title_div {
      display: flex;
      justify-content: center;

      .about_title_img {
        margin: 70px 0 30px 0;
        font-size: 30px;
        font-weight: 700;

        .line_div_box {
          background: #2200ba;
          width: 30px;
          height: 4px;
          margin: auto;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .about_content_div {
      font-size: 14px;
      color: #232931;
      line-height: 48px;
    }

    .about_content_img_box {
      margin: 30px 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .company_develop_div {
      position: relative;
      background: url('../../assets/img/aboutUs/time_bj.jpg') no-repeat center;
      height: 415px;
      margin: 30px 0 30px 0;
      background-size: cover;

      .swiper_about {
        .swiper-slide {
          color: #fff;
          padding: 60px 0 0 0;

          .slide_item {
            width: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            cursor: pointer;

            .title_area {
              font-size: 18px;
              font-weight: 700;
            }

            .gs_img_div {
              margin: 0 auto;
            }
          }
        }

        .swiper-slide:nth-child(1), .swiper-slide:nth-child(6), .swiper-slide:nth-child(7), .swiper-slide:nth-child(12), .swiper-slide:nth-child(13), .swiper-slide:nth-child(18) {
          padding-top: 100px;
        }

        .swiper-slide:nth-child(2), .swiper-slide:nth-child(4), .swiper-slide:nth-child(8), .swiper-slide:nth-child(10), .swiper-slide:nth-child(14), .swiper-slide:nth-child(16) {
          padding-top: 60px;
        }

        .swiper-slide:nth-child(3), .swiper-slide:nth-child(5), .swiper-slide:nth-child(9), .swiper-slide:nth-child(11), .swiper-slide:nth-child(15), .swiper-slide:nth-child(17) {
          padding-top: 40px;
        }
      }

      .swiper-button-prev-about-us,
      .swiper-button-next-about-us {
        background-image: none;
        height: 100%;
        position: absolute;
        margin: 0;
        top: 0;
        z-index: 999;
        // color: red;
      }

      .swiper-button-prev-about-us {
        left: 30px;
        width: 40px;
        height: 40px;
        background-image: url('../../assets/img/aboutUs/jt_left.png');
        background-size: 100% 100%;
        top: 64%;
        transform: translateY(-50%);

        &:after {
          content: "";
          width: 40px;
          height: 40px;
          position: absolute;
          left: 0;
          top: 64%;
          transform: translateY(-50%);
          display: none;
          //   border-style: solid;
        }
      }

      .swiper-button-next-about-us {
        right: 30px;
        width: 40px;
        height: 40px;
        background-image: url('../../assets/img/aboutUs/jt_right.png');
        background-size: 100% 100%;
        top: 64%;
        transform: translateY(-50%);

        &:after {
          content: "";
          position: absolute;
          top: 64%;
          transform: translateY(-50%);
          display: none;
          border-width: 10px;
          border-color: transparent transparent transparent #16f7ff;
          border-style: solid;
        }
      }

      .swiper-container .swiper-wrapper { //Swiper实现无缝匀速滚动样式
        -webkit-transition-timing-function: linear; /*之前是ease-out*/
        -moz-transition-timing-function: linear;
        -ms-transition-timing-function: linear;
        -o-transition-timing-function: linear;
        transition-timing-function: linear;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .about_us_en_div {
    .about_all_div {
      .company_develop_div {
        .swiper-button-prev-about-us,
        .swiper-button-next-about-us {
          display: none;
        }

        .swiper_about {
          .swiper-slide {
            .slide_item {
              .title_area {
                font-size: 14px;
                word-wrap: break-word;
                word-break: break-all;
              }

              .gs_img_div {
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}
</style>
